import useAddressConvert from "@/hooks/useAddressConvert";
import useHandleCopyClick from "@/hooks/useHandleCopyClick";
import useAddressType from "@/store/useAddressType";
import useWallet from "@/store/useWallet";
import { mobileHidden } from "@/utils";
import useGetUserInfo from "@/web3Hooks/useGetUserInfo";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom"
import copy from "@/image/copy.png";
import { LoadingOutlined } from "@ant-design/icons";
import switchImg from "@/image/switchImg.png";
import Return from "@/components/Return";
const PageNoBg = () => {
    const [searchParams] = useSearchParams();
    const id = searchParams.get('id');
    const { t } = useTranslation()
    // 地址转换
    const { addressConvert } = useAddressConvert();
    // 我的地址
    const {
        wallet: { address },
    } = useWallet();
    // 复制
    const { handleCopyClick } = useHandleCopyClick();
    // 获取用户的信息
    const { userInfo, userInfoLod } = useGetUserInfo();
    // 地址类型
    const { setAddressType, addressType } = useAddressType();
    // 地址转换
    const changeAddressType = () => {
        const type = addressType === "0x" ? "fb" : "0x";
        setAddressType(type);
    };
    const dataInfo = [
        {
            text: t('pageNoBg.zxText7'),
            link: ['https://tidenews.com.cn/tmh_news.html?id=6709cb9fa8070500014d81d1&source=weixin', 'https://www.toutiao.com/article/7424678032742416906/?app=news_article&timestamp=1728798927&use_new_style=1&req_id=202410131355273E0823CA54AADC8C949B&group_id=7424678032742416906&wxshare_count=1&tt_from=weixin&utm_source=weixin&utm_medium=toutiao_android&utm_campaign=client_share&share_token=c8a87cd0-c45b-4f13-9e3d-be7bcf2ec2fa&source=m_redirect']
        },
        {
            text: t('pageNoBg.zxText6'),
            link: ['https://www.metaera.hk/gw_detail?id=188924']
        },
        {
            text: t('pageNoBg.zxText5'),
            link: ['https://mp.weixin.qq.com/s/MPAB8yivMMZu1hjRP__fcg',]
        }, {
            text: t('pageNoBg.zxText'),
            link: ['https://page.om.qq.com/page/Oku_7QxFKf3OOmehLApSHGoQ0', 'https://www.jinse.cn/news/blockchain/3681035.html']
        },

    ]
    const returnData = [t('pageNoBg.inviteFriends'), t('pageNoBg.publishNews')]
    return (
        <div className=' max-w-300 mx-auto'>
            <div className=" h-16 flex items-center pl-3.5 text-white bg-green23  md:hidden">
                <Return text={returnData[Number(id)]} />
            </div>
            <div className=" mx-4 mt-2.5 md:mt-0 md:pt-8">
                {
                    id === '0' && <div className=" box-border px-4.5  rounded-md  border-gray3B border-1   mt-3.5 md:mt-0">
                        <div className="h-10.5 flex items-center border-b-1  border-gray3B   justify-between">
                            <span className=" text-xs1 text-grayA8">{t('pageNoBg.recommendedLinks')}：</span>
                            <div className=" font-medium text-sm flex items-center text-white ">
                                {mobileHidden(
                                    `${window.location.origin
                                    }/invitation?inviteAddress=${addressConvert(address)}`,
                                    10,
                                    10
                                )}
                                <img
                                    className=" w-4 h-4     ml-2 cursor-pointer"
                                    onClick={() => {
                                        handleCopyClick(
                                            `${window.location.origin
                                            }/invitation?inviteAddress=${addressConvert(address)}`
                                        );
                                    }}
                                    src={copy}
                                    alt=""
                                />
                            </div>
                        </div>
                        <div className="h-10.5 flex items-center justify-between">
                            <span className=" text-xs1 text-grayA8">{t('pageNoBg.addressOfTheReference')}：</span>
                            <div className=" font-medium text-sm flex items-center text-white ">
                                {userInfoLod ? (
                                    <LoadingOutlined />
                                ) : (
                                    mobileHidden(addressConvert(userInfo.referrer))
                                )}
                                <img
                                    className=" w-4 h-4 mx-2 cursor-pointer"
                                    onClick={changeAddressType}
                                    src={switchImg}
                                    alt=""
                                />
                                <img
                                    className=" w-4 h-4 ml-2 cursor-pointer"
                                    onClick={() => {
                                        handleCopyClick(addressConvert(userInfo.referrer));
                                    }}
                                    src={copy}
                                    alt=""
                                />
                            </div>
                        </div>
                    </div>
                }
                {
                    id === '1' && <>
                        {dataInfo.map((item, index) => {
                            return <div key={index} className=" text-white box-border px-2 py-4 rounded-md  whitespace-normal mt-2 bg-green27 ">
                                {item.text}
                                <span className=" text-green92 cursor-pointer" onClick={() => { window.open(item.link[0]) }}>{t('pageNoBg.zxText1')}</span>
                                {item.link.length > 1 ? t('pageNoBg.zxText2') : ''}
                                {
                                    item.link.length > 1 ? <span className="text-green92 cursor-pointer" onClick={() => { window.open(item.link[1]) }}>{t('pageNoBg.zxText3')}</span> : ''
                                }
                                {t('pageNoBg.zxText4')}
                                {item.link.map(((v, i) => {
                                    return <div className=" flex items-center  overflow-hidden  " onClick={() => { window.open(v) }} >
                                        <p className=" w-full truncate  text-green92 cursor-pointer"> {v}</p>
                                    </div>
                                }))}
                            </div>
                        }
                        )}
                    </>
                }
            </div>

        </div >
    )
}

export default PageNoBg